import React from 'react'
import PropTypes from 'prop-types'

import SEO from '../../../components/atoms/Seo'
import Navbar from '../../../components/organisms/Navbar'
import StripeTable from '../../../components/molecules/StripeTable'
import ProgramPlaceTemplate from '../../../components/templates/ProgramPlaceTemplate'

const OffreLocation = ({ location }) => {
  const title = 'Déposer une annonce de location - Notre offre location'
  const description = 'Découvrez notre offre pour déposer une annonce pour la location ou sous-location de local professionnel'

  return (
    <>
      <SEO
        title={title}
        description={description}>
      </SEO>
      <Navbar location={location} />
      <ProgramPlaceTemplate>
        <StripeTable>
          <stripe-pricing-table
            pricing-table-id='prctbl_1O9TljAZuoCRZZBsOD5EnQTf'
            publishable-key='pk_live_51NqYCvAZuoCRZZBsPc3XZdkKRz9XrKN2xqX8LJiP3NShS8f7Urm4yETpI9bLna9TFKLBUNi4oPwRD11NV2Fhm18m00nM4oVbJO'>
          </stripe-pricing-table>
        </StripeTable>
      </ProgramPlaceTemplate>
    </>
  )
}

OffreLocation.propTypes = {
  location: PropTypes.object.isRequired
}

export default OffreLocation
