import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import media from '../../../utils/media'

const Container = styled.div`
  width: inherit;
  margin-top: 20px;

  ${media.lessThan('md')`
    margin-top: 0px;
  `}

  & > .PricingTable > .PricingTable-grid > .PriceColumn > .PriceColumn-content > .PriceColumn-priceAndButton > .PriceColumn-button {
    display: none !important;
  }

  // .PriceColumn-button {
  //   color: red !important;
  // }
`

const StripeTable = ({ children }) => {
  const [isStripeLoaded, setStripeLoaded] = useState(false)

  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://js.stripe.com/v3/pricing-table.js'
    script.async = true
    script.onload = () => setStripeLoaded(true)
    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  return (
    <Container>
      {isStripeLoaded && children}
    </Container>
  )
}

StripeTable.propTypes = {
  children: PropTypes.node.isRequired
}

export default StripeTable
